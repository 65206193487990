/**
 * These constants will be available in Vue via `this.${CONSTANT_NAME}`
 *  ex: `this.$userTypes`
 */
const scorecardTypes = {
    STROKE_PLAY: 1,
    STABLEFORD: 2,
    MATCH_PLAY: 5,
    SKINS: 6,
    NASSAU: 7,
};

const scoreEntryTypes = {
    SCORE_PUTTS: 1,
    TRADITIONAL: 2,
    SG_LITE: 3,
    SG_FULL: 4,
    NO_SCORE: 5,
};

const strokesGainedFacets = {
    IMPROVEMENT_FACET_CHIP: 1,
    IMPROVEMENT_FACET_SAND: 2,
    IMPROVEMENT_FACET_PUTTING: 4,
    IMPROVEMENT_FACET_APPROACH : 5,
    IMPROVEMENT_FACET_DRIVE  : 6,
};

const userTypes = {
    ADMIN: 16,
    INSTRUCTOR: 17,
    PLAYER: 9,
};

const userStatuses = {
    ACTIVE: 1,
    INACTIVE: 2,
    PENDING: 3,
};


const status = {
    ACTIVE: 1,
    INACTIVE: 2,
    DELETED: 33,
};

const admin = {
    COURSE_EDIT_MODE_HOLE: 1,
    COURSE_EDIT_MODE_PRACTICE_AREA: 2,
};

export {
    admin,
    scoreEntryTypes,
    scorecardTypes,
    status,
    strokesGainedFacets,
    userStatuses,
    userTypes,
}
